// API Key is a dummy, it's injected on origin request
let redirectSignIn = `https://${process.env.GATSBY_PUBLIC_DOMAIN_NAME}/search`;
let redirectSignOut = `https://${process.env.GATSBY_PUBLIC_DOMAIN_NAME}/login`;

module.exports = {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.GATSBY_AWS_COGNITO_CLIENT_ID,
      userPoolId: process.env.GATSBY_AWS_COGNITO_USER_POOL_ID,
      loginWith: {
        oauth: {
          domain: process.env.GATSBY_AWS_COGNITO_DOMAIN,
          scopes: [
            "openid",
            "email",
            "phone",
            "profile",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: [redirectSignIn],
          redirectSignOut: [redirectSignOut],
          responseType: "code",
        },
        username: "false",
        email: "true",
        phone: "false",
      },
    },
  },
  API: {
    GraphQL: {
      endpoint: process.env.GATSBY_AWS_APPSYNC_GRAPHQL_ENDPOINT,
      defaultAuthMode: process.env.GATSBY_APPSYNC_AUTH_TYPE,
      apiKey: process.env.GATSBY_AWS_APPSYNC_GRAPHQL_API_KEY,
      region: process.env.GATSBY_AWS_REGION,
    },
  },
};
